<template>
  <div class="footer">
    <div class="wrapper">
    <img class='logo' src="/img/logo.png" alt="logo"/>
    <div class="footerCont">
      <ul>
        <li>联系我们</li>
        <li> <i class="iconfont" style="padding-right: 10px;">&#xe601;</i> <span>186 - 7555 - 4201</span> </li>
        <li> <i class="iconfont" style="padding-right: 10px;">&#xe61c;</i> <span>yanglixia@gdbbk.com</span> </li>
        <li> 地址：广东省深圳市南山区前海湾壹号T3栋</li>
      </ul>
      <div class="right">
          <img class='wxlogo' src="/img/wx.JPG" alt="logo"/>
      </div>
    </div>
		 	<div style="width: 410px; margin: 0px auto; padding: 20px 0px; display: flex; color: rgb(147, 147, 147);">
		 		<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44190002006706" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="/img/备案图标.png" alt="备案图标" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">粤公网安备 44190002006706号</p></a>
		   <span style="padding:0 5px;">|</span> <a href="https://beian.miit.gov.cn" target="_blank" style="display: inline; color: rgb(147, 147, 147);"> 粤ICP备2023141855号</a> 
     	</div>
<div>

</div>
</div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  }
}
</script>

<style scoped>
.footer{
     background: #22272b;
    padding: 30px 0;
    color: #fff;
}
.wrapper{
      width: 1080px;
    margin: 0 auto;
    font-size: 14px;
}
.logo{
   /* width: 50px;
   height: 50px; */
}
a{
  color: #c5c5c7;
}
.footerCont{
      margin-top: 20px;
    display: flex;
    justify-content: space-between;
    /* border-bottom: 1px solid #fff; */
    border-bottom: 1px solid #39424d;
}
.footerCont ul{
   padding: 0;
}
li{
     font-size: 14px;
    line-height: 38px;
    letter-spacing: 1px;
        color: #c5c5c7;
}
li:first-child{
        font-size: 17px;
    font-weight: bold;
    line-height: 21px;
    margin: 10px 0 20px 0;
    height: 21px;
    padding-left: 16px;
    color: #fff;
    letter-spacing: 4px;
    border-left: 3px solid #fff;
}
.right{
      width: 200px;
    /* margin: 0 auto; */
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.wxlogo{
width: 100px;
}
.right p{
   line-height: 50px;
}
.Copyright{
    margin-top: 24px;
        color: #c5c5c7;
}

/*  pad pro 1024(pc一样)*/
@media (min-width: 770px) and (max-width: 1030px) {
  .wrapper{
      width: 80%;
  }
}


/* 超小屏幕（手机，小于 768px） */
@media (max-width: 768px) {
   .wrapper{
      width: 90%;
  }
  .footer{
    padding: 15px 0;
  }
  .footerCont{
    margin-top: 10px;
  }

.logo{
    width: 100px;
}

}

</style>

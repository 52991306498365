import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import "./assets/styles/index.css";
import router from './router/route'

Vue.use(ElementUI);
Vue.config.productionTip = false

const vm = new Vue({
  render: h => h(App),
  router,
}).$mount('#app')

if (window.__MT__ && window.__MT__.initVuePlugin) {
  window.__MT__.initVuePlugin(Vue)
  window.__MT__.$vm = vm;
}

import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
export default new VueRouter({
  routes:[
    {
      path:"/",
      redirect:'/home',
      component: () => import('@/views/home')
  },
    {
        path:"/home",
        component: () => import('@/views/home')
    },
    {
      path:"/product",
      component: () => import('@/views/product')
  },
  {
    path:"/solution",
    component: () => import('@/views/solution')
  },
  {
    path:"/contact",
    component: () => import('@/views/contact')
  }
]
})
<template>
  <div>
      <div class="header">
        <div class='logo'> 
          <img src="/img/logo.png" alt="logo"/>
          <!-- <span>UP科技</span>  -->
        </div>
        <div class="headerContent">
          <el-menu :default-active="activeIndex" text-color='#333' active-text-color='#3EB2FF' class="el-menu-demo" mode="horizontal" >
            <el-menu-item index="1"> <router-link to="home">首页</router-link></el-menu-item>
            <el-menu-item index="2"> <router-link to="product">产品服务</router-link></el-menu-item>
            <el-menu-item index="3"> <router-link to="solution">解决方案</router-link> </el-menu-item>
            <el-menu-item index="4"> <router-link to="contact">联系我们</router-link> </el-menu-item>
            <!-- <el-menu-item index="5">登录</el-menu-item> -->
          </el-menu>
        </div>
          <div class='phone'> 
            <i class="iconfont">&#xe787;</i> <span>186 - 7555 - 4201</span> 
          </div>
      </div>
      <div class="mobile-header">
        <img class='mobile-logo' src="/img/logo.png" alt="logo"/>
        <div > 
        <span>{{title}}</span> 
        </div>
        <i class="iconfont nav" @click="nav = true">&#xe636;</i>   
    </div>
    <el-drawer
  :visible.sync="nav"
  :show-close='false'
  size='60%'
  :direction="direction" ref='drawer'>
  <el-menu
      default-active="activeIndex"
      class="el-menu-vertical-demo"
       @select="handleSelect"
      >
      <el-menu-item index="1">
        <i class="el-icon-menu"></i>
        <span slot="title"><router-link to="home">首页</router-link></span>
      </el-menu-item>
      <el-menu-item index="2">
        <i class="el-icon-menu"></i>
        <span slot="title"><router-link to="product">产品服务</router-link></span>
      </el-menu-item>
      <el-menu-item index="3" >
        <i class="el-icon-document"></i>
        <span slot="title"><router-link to="solution">解决方案</router-link></span>
      </el-menu-item>
      <el-menu-item index="4">
        <i class="el-icon-setting"></i>
        <span slot="title"><router-link to="contact">联系我们</router-link></span>
      </el-menu-item>
      <!-- <el-menu-item index="5">
        <i class="el-icon-setting"></i>
        <span slot="title">登录</span>
      </el-menu-item> -->
    </el-menu>
</el-drawer>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    msg: String
  },
  data(){
    return{
       activeIndex: '',
       title:'首页',
       nav:false,
        direction: 'rtl',

    }
  },
  mounted(){
   if(window.location.href.indexOf('home')>=0){
  this.activeIndex ='1';
   }else if(window.location.href.indexOf('product')>=0){
  this.activeIndex ='2';
   }else if(window.location.href.indexOf('solution')>=0){
  this.activeIndex ='3';
   }else if(window.location.href.indexOf('contact')>=0){
  this.activeIndex ='4';
   }
  },
  methods:{
     handleSelect(key) {
       this.$refs.drawer.closeDrawer();
       if(key ==1){
          this.title='首页';
       }else if(key ==2){
          this.title='产品服务';
       }else if(key ==3){
          this.title='解决方案';
       }else if(key ==2){
          this.title='联系我们';
       }
      }
  }


}
</script>

<style scoped>
a{
  display: inline-block;
    width: 80%;
}
.header{
    height: 80px;
    width: 100%;
    line-height: 80px;
       display: flex;
    justify-content: center;
    box-sizing: border-box;
    border-bottom: solid 1px #e6e6e6;
}
.logo{
       display: flex;
    position: absolute;
    align-items: center;
}
.logo img{
  width: auto;
    /* height: 50px; */
}
  .phone img,.mobile-logo img{
      width:auto;
    }
 .logo span,.mobile-logo span{
       font-size: 18px;
    line-height: 50px;
    height: 50px;
    font-weight: bold;
 }
  .phone span{
      font-size: 18px;
      font-weight: bold;
    }

  .el-menu{
          height: 80px;
    }
.phone{
    position: absolute;
    top: 0;
    line-height: 80px;
}
.login{
    position: absolute;
    right: 10%;
    top: 0;
    line-height: 80px;
}
.el-menu--horizontal>.el-menu-item{
  color:#333;
  font-size: 18px;
  font-weight: bold;
      height: 80px;
    line-height: 80px;
}
.mobile-header{
  height: 56px;
    width: 100%;
    line-height: 56px;
       display: flex;
    justify-content: center;
    box-sizing: border-box;
    border-bottom: solid 1px #e6e6e6;
    display: none;
}
.nav{
color: #000;
font-size: 20px;
position: absolute;
right:10px;
}


/* 笔记本 电脑  */
 @media (min-width: 1031px) {
  .header{
      padding: 0 5%;
      display: flex ;
    }
    .logo{
      height: 80px;
        left: 5%;
    }
    .phone{
        right: 5%;
    }
    .el-menu--horizontal>.el-menu-item{
      padding: 0 50px;
    }
      .mobile-header{
      display: none;
    }
}

/*  pad pro 1024(pc一样)*/
@media(min-width:770px) and (max-width:1030px){
    .header{
      padding: 0 2%;
      display: flex ;
    }
    .logo{
        left: 2%;
        height: 80px;
    }
    .phone{
        right: 2%;
    }
    .el-menu--horizontal>.el-menu-item{
      padding: 0 26px;
    }
    .mobile-header{
      display: none;
    }
}

/* 超小屏幕（手机，小于 768px） pad*/
@media (max-width: 768px) { 
    .header{
      display: none ;
    }
    .mobile-header{
      display: flex;
      font-size: 18px;
    font-weight: bold;
    }
    .mobile-logo{
    position: absolute;
    left: 10px;
    align-items: center;
    top: 6px;
    width: 100px;
}
.el-menu-item{
  color: #333;
    font-weight: bold;
}


}
</style>

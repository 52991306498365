<template>
  <div>
    <el-carousel :height="carouselHeight">
      <el-carousel-item
        v-for="item in imgArr"
        :key="item.index"
        :style="'background-image:url(' + item.img + ');'"
      >
        <!-- <img :src = "item.img" /> -->
        <h3 class="small" :class="item.index==1? 'large':''">{{ item.content }}</h3>
        <p class="atention"><router-link to="home">立即咨询>>></router-link></p>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    msg: String,
  },
  data() {
    return {
      activeIndex: "",
      carouselHeight: "450px",
      nav: false,
      direction: "ltr",
      imgArr: [
        {
          img: "/img/banner1.jpg",
          content: "UP灵活用工平台",
          index: 1,
        },
        {
          img: "/img/banner2.jpg",
          content: "为企业提供高效、专业、低费、合规的共享经济服务",
          index: 2,
        },
      ],
    };
  },
  mounted() {
    if (window.location.href.indexOf("home") >= 0) {
      this.activeIndex = "1";
    } else if (window.location.href.indexOf("product") >= 0) {
      this.activeIndex = "2";
    } else if (window.location.href.indexOf("solution") >= 0) {
      this.activeIndex = "3";
    } else if (window.location.href.indexOf("contact") >= 0) {
      this.activeIndex = "4";
    }
    
    if (window.screen.width <= 768) {
      this.carouselHeight = "250px";
      this.imgArr=[
        {
          img: "/img/bannerMobile1.JPG",
          content: "UP灵活用工平台",
          index: 1,
        },
        {
          img: "/img/bannerMobile2.JPG",
          content: "为企业提供高效、专业、低费、合规的共享经济服务",
          index: 2,
        },
      ];
    } else if (window.screen.width < 1025 && window.screen.width > 768) {
      this.carouselHeight = "400px";
    } else {
      this.carouselHeight = "450px";
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      if (key == 5) {
        window.open("https://labor-cloud-portal-prj.tenserpay.xyz/");
      }
    },
  },
};
</script>

<style scoped>
.el-carousel__item {
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.atention {
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  bottom: 35px;
  left: 150px;
}

/* 笔记本 电脑  */
@media (min-width: 1031px) {
  .el-carousel__container {
    height: 450px;
  }
  .el-carousel__item h3 {
    font-size: 38px;
    line-height: 80px;
    margin-left: 10%;
    max-width: 600px;
  }
  .large{
     font-size: 53px!important;
    letter-spacing: 4px;
    margin-left: 20%!important;
  }
}

/*  pad pro 1024(pc一样)*/
@media (min-width: 770px) and (max-width: 1030px) {
  .el-carousel__container {
    height: 400px;
  }
  .el-carousel__item h3 {
    font-size: 25px;
    margin-left: 10%;
   max-width:430px;
   line-height: 65px;
  }
    .large{
     font-size: 42px!important;
    letter-spacing: 4px;
    margin-left: 15%!important;
  }
}

/* 超小屏幕（手机，小于 768px） */
@media (max-width: 768px) {
  .el-carousel__container {
    height: 250px;
  }
  .el-carousel__item h3 {
    text-align: center;
    margin-left: 10px;
    font-size: 20px;
    line-height: 38px;
    padding: 30px;
     max-width: 45%;
  }
  .atention {
    font-size: 13px;
    bottom: 25px;
    left: 30px;
  }
}
</style>
